var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"\n        col-12 col-lg-8\n        offset-lg-2\n        col-xl-6\n        offset-xl-3\n        login-form login-signin\n      "},[_c('div',{staticClass:"text-center mb-10 mb-lg-20"},[_c('h3',{staticClass:"font-size-h1"},[_vm._v("Sign up")]),(_vm.application)?_c('p',{staticClass:"text-dark-75 font-weight-semi-bold"},[_vm._v(" Sign up to continue to "+_vm._s(_vm.application.name)+" ")]):_vm._e()]),[_c('form',{staticClass:"form",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.email.$model),expression:"$v.form.email.$model"}],ref:"input:email",class:[
                'form-control form-control-solid h-auto py-5 px-6',
                { 'is-invalid': _vm.$v.form.email.$error } ],attrs:{"type":"email","placeholder":"Email address","disabled":_vm.isFormLoading},domProps:{"value":(_vm.$v.form.email.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.form.email, "$model", $event.target.value)}}})]),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.password.$model),expression:"$v.form.password.$model"}],ref:"input:password",class:[
                'form-control form-control-solid h-auto py-5 px-6',
                { 'is-invalid': _vm.$v.form.password.$error } ],attrs:{"type":"password","placeholder":"Password","disabled":_vm.isFormLoading},domProps:{"value":(_vm.$v.form.password.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.form.password, "$model", $event.target.value)}}})]),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.password_confirm.$model),expression:"$v.form.password_confirm.$model"}],ref:"input:password_confirm",class:[
                'form-control form-control-solid h-auto py-5 px-6',
                { 'is-invalid': _vm.$v.form.password_confirm.$error } ],attrs:{"type":"password","placeholder":"Password (Confirmation)","disabled":_vm.isFormLoading},domProps:{"value":(_vm.$v.form.password_confirm.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.form.password_confirm, "$model", $event.target.value)}}})]),_c('div',{staticClass:"form-group"},[_c('button',{ref:"input:submit",staticClass:"\n                btn btn-primary btn-block\n                font-weight-bold\n                px-9\n                py-4\n                my-3\n                font-size-3\n              ",attrs:{"type":"submit","disabled":_vm.isFormLoading}},[_vm._v(" Sign up ")])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col form-group"},[_vm._v(" Already have an account? "),_c('router-link',{attrs:{"to":{
                path: '/signin',
                query: _vm.request,
              }}},[_vm._v(" Login ")])],1)])]],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }