













































































































import Vue from 'vue';
import { validationMixin } from 'vuelidate';
import { required, sameAs } from 'vuelidate/lib/validators';
import { Application, ApplicationService } from '../../../libs/application';
import { OAuth2Service } from '../../../libs/oauth2';
import { LocalDatabaseStrategy } from '../../../libs/strategies';
import { routeToErrorPage } from '../../../libs/util';

export default Vue.extend({
  mixins: [validationMixin],
  data() {
    return {
      isMounted: false,
      isFormLoading: false,

      request: OAuth2Service.getAuthorizationRequestFromRoute(this.$route),
      application: null as Application | null,
      connection: this.$route.query.connection.toString(),

      form: {
        email: '',
        password: '',
        password_confirm: '',
      },
    };
  },
  validations: {
    form: {
      email: {
        required,
      },
      password: {
        required,
      },
      password_confirm: {
        required,
        sameAsPassword: sameAs('password'),
      },
    },
  },

  async mounted() {
    if (!this.request.client_id) {
      routeToErrorPage();
      return;
    }

    try {
      this.$set(
        this,
        'application',
        await ApplicationService.getByClientID(this.request.client_id),
      );
    } catch (e) {
      routeToErrorPage();
      return;
    }

    this.$set(this, 'isMounted', true);
  },

  methods: {
    async onSubmit() {
      this.$set(this, 'isFormLoading', true);
      try {
        await LocalDatabaseStrategy.signup(
          this.request.client_id,
          this.connection,
          this.form.email,
          this.form.password,
        );
      } catch (e) {
        console.error(e);
        return;
      } finally {
        this.$set(this, 'isFormLoading', false);
      }

      // On success, redirect back to authorize page
      this.$router.replace({
        path: '/authorize',
        query: this.$route.query,
      });
    },
  },
});
