import { ROUTER_ERROR_PAGE_NAME } from '@/router/constants';
import router from '../../router';

// TODO: Find a way to pass 'e'
export function routeToErrorPage(): void {
  router.replace({
    name: ROUTER_ERROR_PAGE_NAME,
    params: {
      pathMatch: router.currentRoute.path,
    },
    query: router.currentRoute.query,
  });
}
